import { createFeature } from '@ngrx/store';
import { reducer } from './user.reducer';
import { userSelectors } from './user.selectors';
import { userActions } from '@store/user/user.actions';

export const selectors = userSelectors;
export const actions = userActions;

export const usersFeature = createFeature({
  name: 'users',
  reducer,
  extraSelectors: () => userSelectors,
});
