import { createReducer, on } from '@ngrx/store';
import { userActions } from './user.actions';

import { IUser } from '@interfaces';

export interface UserState {
  current: string;
  entities: Record<string, IUser>;
  loading: boolean;
  updated: number;
  message: string;
}

export const initialState: UserState = {
  current: null,
  entities: {},
  loading: false,
  updated: Date.now(),
  message: null,
};

export const reducer = createReducer(
  initialState,

  /* LOGIN */

  on(userActions.authenticate, state => ({ ...state, loading: true, message: null })),
  on(userActions.authenticateSuccess, (state, { user }) => ({
    ...state,
    current: user.uuid,
    entities: { ...state.entities, [user.uuid]: user },
    message: null,
    loading: false,
    updated: Date.now(),
    error: null,
  })),
  on(userActions.authenticateFailure, (state, payload) => ({
    ...state,
    current: null,
    message: payload ? payload.message : null,
    loading: false,
    updated: Date.now(),
  })),

  /* LOGOUT */

  on(userActions.logout, state => ({
    ...state,
    current: null,
    message: null,
    loading: false,
    updated: Date.now(),
  })),

  /* SET */

  on(userActions.setCurrent, (state, { user }) => ({
    ...state,
    current: user.uuid,
    entities: { ...state.entities, [user.uuid]: user },
    message: null,
    loading: false,
    updated: Date.now(),
  })),

  /* FETCH_ONE */

  on(userActions.fetchOneSuccess, (state, { entity }) => {
    return { ...state, loading: false, entities: { ...state.entities, [entity.uuid]: entity }, updated: Date.now() };
  }),

  /* FETCH_ALL */

  on(userActions.fetchAllSuccess, (state, { entities }) => ({
    ...state,
    loading: false,
    entities: entities.reduce((p, c) => ({ ...p, [c.uuid]: c }), {}),
    updated: Date.now(),
  })),
  on(userActions.fetchAllError, (state, payload) => ({
    ...state,
    loading: false,
    error: payload.error,
  })),

  /* CREATE */

  on(userActions.createSuccess, (state, { entity }) => ({
    ...state,
    loading: false,
    entities: { ...state.entities, [entity.uuid]: entity },
    updated: Date.now(),
  })),
  on(userActions.createError, (state, payload) => ({
    ...state,
    loading: false,
    error: payload.error,
  })),

  /* UPDATE */

  on(userActions.updateSuccess, (state, { entity }) => {
    return { ...state, loading: false, entities: { ...state.entities, [entity.uuid]: entity }, updated: Date.now() };
  }),
  on(userActions.updateError, (state, payload) => ({
    ...state,
    loading: false,
    error: payload.error,
  })),

  /* UPDATE */

  on(userActions.updateProfileSuccess, (state, { user }) => {
    return {
      ...state,
      entities: { ...state.entities, [user.uuid]: user },
      loading: false,
      updateProfiled: Date.now(),
    };
  }),
  on(userActions.updateProfileError, (state, payload) => ({
    ...state,
    loading: false,
    error: payload.error,
  })),

  /* DESTROY */

  on(userActions.destroySuccess, (state, { id }) => {
    const entities = { ...state.entities };
    delete entities[id];
    return { ...state, loading: false, entities, updated: Date.now() };
  }),
  on(userActions.destroyError, (state, payload) => ({
    ...state,
    loading: false,
    error: payload.error,
  })),

  /* MISC */

  on(
    userActions.fetchAll,
    userActions.fetchOne,
    userActions.create,
    userActions.update,
    userActions.updateProfile,
    userActions.destroy,
    state => ({ ...state, loading: true })
  )
);
